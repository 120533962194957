<template>
    <div class="o-layout o-layout--medium">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <title-text
                        :title="PROVIDER.ProviderSettingsPage.Title"
                        class="qa-provider-settings-title"
                    />
                </div>

                <div class="col-12 mt-3">
                    <tab-navigation
                        page-name="leveranciersgegevens"
                        :navigation-tabs="navigationTabs"
                    />
                </div>

                <div class="col-12 mt-4">
                    <recess-card variant="variant1">
                        <div class="o-layout o-layout--small">
                            <router-view />
                        </div>
                    </recess-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import TitleText from '@/components/atoms/TitleText'

const TabNavigation = () => import('@/../../shared/components/TabNavigation/TabNavigation')

export default {
    name: 'ProviderSettingsPage',
    components: {
        TitleText,
        TabNavigation
    },
    data() {
        return {
            PROVIDER,
            TitleText,
            navigationTabs: [
                {
                    title: PROVIDER.ProviderSettingsPage.NavigationTabs.SettingsTitle,
                    path: 'algemeen'
                },
                {
                    title: PROVIDER.ProviderSettingsPage.NavigationTabs.EmailTitle,
                    path: 'email'
                },
                {
                    title: PROVIDER.ProviderSettingsPage.NavigationTabs.LegalCompanyTitle,
                    path: 'handelsnamen'
                }
            ]
        }
    }
}
</script>